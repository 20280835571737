<template>
  <div class="content-area cars-list">
    <hero-bar>
      {{ $t('cars') }}
    </hero-bar>

    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced bg-none">
        <cars-table />
      </card-component>
    </section>
  </div>
</template>

<script>
export default {
  title: ({ $i18n }) => $i18n.t('cars'),
  components: {
    HeroBar: () => import('@/components/HeroBar'),
    CardComponent: () => import('@/components/CardComponent'),
    CarsTable: () => import('@/components/CarsTable'),
  },
}
</script>
